import { navigate, PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '~/actions/auth';
import Button from '~/components/Button';

// import Button from '~/components/Button';
import requireUser, { UserProps } from '../utils/requireUser';

const ProfilePage: FC<PageProps & UserProps> = ({ location, user }) => {
  const dispatch = useDispatch();

  const handleOnClickLogout = async () => {
    dispatch(logout());
    await navigate('/');
  };

  return (
    <div className="flex min-h-main flex-col items-center justify-center">
      <div className="flex flex-col space-y-3">
        <span>En cours de construction...</span>

        <Button color="neutral" onClick={handleOnClickLogout} variant="outline">
          Déconnexion
        </Button>
      </div>
      {/* <Button to="/espaces/create">Créer un espace de travail</Button> */}
    </div>
  );
};

export default requireUser(ProfilePage);
