import { UserData } from 'packages/innedit';

import * as types from '../constants/actionTypes';

/**
 * action to logout
 *
 * @returns {{payload: Promise<void>, type: string}}
 */
export function logout() {
  return {
    payload: UserData.signOut(),
    type: types.LOGOUT,
  };
}

/**
 * action to update user
 *
 * @param {User} user
 * @returns {{payload: Promise, type: string}}
 */
export function update(user) {
  return {
    payload: UserData.update(user),
    type: types.USER_UPDATE,
  };
}
